import { selectTaskById, selectTasks } from '@/redux/models/TaskModel/selectors';
import { TaskModelStateFields } from '@/redux/models/TaskModel/types';
import { store } from '@/redux/store';
import { reactQueryClient } from '@/components/ReduxWrappedComponent';
import type { Tag } from '@/components/common/pickers/TagPicker/TagPicker.types';
import type {
  IFetchUsersAndGroupTreeResponse,
} from '@/services/RestApiClientFactory/modules/ApiClientUsers/ApiClientUsers.types';
import type { IUseTagsQueryData } from '@/reactQuery/Tags/hooks/useQueryTags/types';
import { groupTags } from '@/reactQuery/Tags/hooks/useQueryTags/utils';
import { getQueryTagsKey } from '@/reactQuery/Tags/queryKeys';
import { getUserByKey } from '@/reactQuery/Users/fetchUsersAndGroupTree.utils';
import { getQueryPeoplePickerKey } from '@/reactQuery/Users/queryKeys';
import { DEFAULT_BREAK_SYMBOL } from '@/utils/constants';
import { ReportEntityType } from '@/types/types';
import type { ReportEntityDataProviderInterface } from './ReportEntityDataProviderInterface';

export class ReportEntityDataProvider implements ReportEntityDataProviderInterface {
  private state: unknown;

  constructor() {
    this.state = null;
    window.addEventListener('tasksLoaded', this.#forceReload, false);
    window.addEventListener('tagsLoaded', this.#forceReload, false);
    window.addEventListener('usersLoaded', this.#forceReload, false);
  }

  getItemBreadcrumb(id: string | number, type: ReportEntityType): string {
    this.#loadStateIfEmpty();

    switch (type) {
      case ReportEntityType.TASK: {
        const visitedTaskTreeIds = {};
        let returnString = '';
        const taskData = selectTasks(this.state, {
          field: TaskModelStateFields.TASKS,
        });
        if (taskData) {
          while (id !== 0) {
            const task = taskData[id];
            if (!task) {
              return '';
            }

            const breakSymbol = returnString !== '' ? DEFAULT_BREAK_SYMBOL : '';
            returnString = task.name + breakSymbol + returnString;
            id = Number(task.parent_id);

            if (visitedTaskTreeIds[id]) {
              console.warn('Incorrect tasks tree structure for id:', id);
              return '';
            }
            visitedTaskTreeIds[id] = true;
          }
        }
        return returnString;
      }

      default: {
        throw new Error(`Not recognized type ${type}`);
      }
    }
  }

  getItemName(id: string | number, type: ReportEntityType): string {
    this.#loadStateIfEmpty();

    switch (type) {
      case ReportEntityType.TASK: {
        const task = selectTaskById(this.state, {
          field: TaskModelStateFields.TASKS,
          id,
        });

        return task.name ?? '';
      }

      case ReportEntityType.TAG: {
        const ids = typeof id === 'number' ? [id] : id.split(',');
        const tags = reactQueryClient.getQueryData(getQueryTagsKey()) as IUseTagsQueryData || [];
        const tagsData = ids.map((id) => tags.find((item) => +item.tagId === id)) as Tag[];

        const { tagNames } = groupTags(tagsData);

        return tagNames.join(', ');
      }

      case ReportEntityType.USER: {
        const { users } = reactQueryClient.getQueryData(
          getQueryPeoplePickerKey(),
        ) as IFetchUsersAndGroupTreeResponse;
        const user = getUserByKey(users, id);

        return user?.display_name ?? user?.email ?? '';
      }

      default: {
        throw new Error(`Not recognized type ${type}`);
      }
    }
  }

  #loadStateIfEmpty() {
    if (this.state === null) {
      this.state = store.getState();
    }
  }

  #forceReload() {
    this.state = store.getState();
  }
}
