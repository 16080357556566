import { fromJS } from 'immutable';
import get from 'lodash/get';
import type { PayloadAction } from '@/types/reduxTypes';
import type { SettingModelState } from './types';
import { SettingModelActions, SettingsModelStateFields } from './types';
import type { Reducer } from 'redux';

const initialState: SettingModelState = fromJS({
  [SettingsModelStateFields.RUNTIME_SETTINGS]: {},
  [SettingsModelStateFields.CURRENCY_SETTING]: {},
  [SettingsModelStateFields.SYNC_INTEGRATIONS_LOADING]: false,
  [SettingsModelStateFields.PRODUCT_METADATA]: {},
});

export const settingModelReducer: Reducer<SettingModelState, PayloadAction> = (
  state: SettingModelState = initialState,
  action: PayloadAction = {} as PayloadAction,
): SettingModelState => {
  switch (action.type) {
    case SettingModelActions.FETCH_SETTING_SUCCESS: {
      const type = get(action, ['payload', 'type']);
      const name = get(action, ['payload', 'name']);
      const value = get(action, ['payload', 'value']);
      return state.setIn([type, name], value);
    }

    case SettingModelActions.UPDATE_INTEGRATIONS_LOADING: {
      const data = get(action, ['payload']);

      return state.setIn([SettingsModelStateFields.SYNC_INTEGRATIONS_LOADING], data);
    }

    default: {
      return state;
    }
  }
};
