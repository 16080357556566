import { DEFAULT_COLOR } from '@/utils/constants';
import { ArchivedFilter } from './types';

export const getDefaultValue = () => ({
  color: DEFAULT_COLOR,
  name: '',
});

export const getArchivedFilterArgument = (archivedFilter) => {
  switch (archivedFilter) {
    case ArchivedFilter.FILTER_ARCHIVED_NO_FILTERING:
      return false;
    case ArchivedFilter.FILTER_ARCHIVED_ONLY_ACTIVE_TASKS:
    default:
      return 0;
  }
};
