import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { domain as ApprovalModelDomain } from '@/redux/models/ApprovalsModel';
import { domain as FilterModelDomain } from '@/redux/models/FilterModel';
import { FilterSlice } from '@/redux/models/FilterModel/reducer';
import { MainMenuModel } from '@/redux/models/MainMenuModel';
import { domain as MessagesModelDomain } from '@/redux/models/MessagesModel';
import { domain as ReportModelDomain } from '@/redux/models/ReportModel';
import { domain as SettingModelDomain } from '@/redux/models/SettingModel';
import { domain as TaskModelDomain } from '@/redux/models/TaskModel';
import { domain as TimeSheetModelDomain } from '@/redux/models/TimeSheetModel';
import { transformPersistStringDateToObject } from '@/redux/utils.store';
import { REDUX_CACHE_BUSTER } from '@/utils/constants';
import { approvalModelReducer } from './models/ApprovalsModel/reducer';
import { MainMenuSlice } from './models/MainMenuModel/reducer';
import { messageModelReducer } from './models/MessagesModel/reducer';
import { reportModelReducer } from './models/ReportModel/reducer';
import { settingModelReducer } from './models/SettingModel/reducer';
import { taskModelReducer } from './models/TaskModel/reducer';
import { timeSheetModelReducer } from './models/TimeSheetModel/reducer';

const rootReducers = combineReducers({
  [FilterModelDomain]: FilterSlice.reducer,
  [ReportModelDomain]: reportModelReducer,
  [TaskModelDomain]: taskModelReducer,
  [SettingModelDomain]: settingModelReducer,
  [MessagesModelDomain]: messageModelReducer,
  [ApprovalModelDomain]: approvalModelReducer,
  [TimeSheetModelDomain]: timeSheetModelReducer,
  [MainMenuModel]: MainMenuSlice.reducer,
});

export type IRootState = typeof rootReducers;

export const reducer = persistReducer({
  key: REDUX_CACHE_BUSTER,
  storage,
  whitelist: [FilterModelDomain, MainMenuModel],
  transforms: [transformPersistStringDateToObject()],
}, rootReducers);
