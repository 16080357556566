import type { AnyMap } from '@/types/types';
import type { Map } from 'immutable';

export enum ApprovalModelActions {
  FETCH_APPROVALS = 'FETCH_APPROVALS',
  UPDATE_APPROVALS = 'UPDATE_APPROVALS',
  FETCH_ACTION_APPROVALS = 'FETCH_ACTION_APPROVALS',
  FETCH_APPROVALS_SUCCESS = 'FETCH_APPROVALS_SUCCESS',
  LOADING_APPROVALS = 'LOADING_APPROVALS',
}

export enum ApprovalModelStateFields {
  APPROVALS = 'tags',
  LOADING = 'loadingApprovals',
}

type ApprovalModel = {
  [ApprovalModelStateFields.APPROVALS]: AnyMap;
  [ApprovalModelStateFields.LOADING]: boolean;
};

export type ApprovalModelState = Map<keyof ApprovalModel, ApprovalModel[keyof ApprovalModel]>;

export enum ApprovalStatus {
  OPEN = 0,
  APPROVED = 1,
  PENDING = 2,
  REJECTED = 3,
  APPROVED_AUTOMATICALLY = 4,
  APPROVED_PARTIALLY = 5,
  REMINDER_SENT_TO_APPROVER = 6,
  REMINDER_SENT_TO_USER = 7,
}
