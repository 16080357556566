import { useQuery } from '@tanstack/react-query';
import {
  DateFormat,
  type DateFormatDict,
  DurationFormat,
  TimeFormat,
  type TimeFormatDict,
} from '@/redux/models/SettingModel/types';
import { restApiClient } from '@/services/instances/restApiClient';
import { getQueryConfigKey } from '@/reactQuery/Config/queryKeys';
import type { DurationFormat as DurationFormatName } from '@/types/types';
import { config } from '@/config';

export const useQueryConfig = () => useQuery({
  queryKey: getQueryConfigKey(),
  queryFn: async () => {
    const apiConfig = await restApiClient.frontendConfig.get();

    return {
      ...config,
      ...apiConfig,
    };
  },
  select: (data) => {
    const { groupSetting } = data;

    return ({
      ...data,
      groupSetting: {
        ...groupSetting,
        disallowForceSynchForNonAdmin: !!parseInt(groupSetting.disallowForceSynchForNonAdmin),
        durationFormat: DurationFormat[groupSetting.durationFormat] as DurationFormatName,
        timeFormat: TimeFormat[groupSetting.timeFormat] as TimeFormatDict,
        dateFormat: DateFormat[groupSetting.dateFormat] as DateFormatDict,
      },
    });
  },
  initialData: config,
});
