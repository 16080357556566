import { fork, put, takeLatest } from 'redux-saga/effects';
import { restApiClient } from '@/services/instances/restApiClient';
import { timeService } from '@/services/instances/timeService';
import type { PartialPayloadAction } from '@/types/reduxTypes';
import { config } from '@/config';
import { ApprovalModelActions } from './types';
import { fetchApprovalsSuccess, loadingApprovals, updateApprovals } from './actions';

export const ApprovalModelSagas = [
  function* () {
    yield fork(function* () {
      yield takeLatest(ApprovalModelActions.FETCH_APPROVALS, onFetchApprovals);
    });
    yield fork(function* () {
      yield takeLatest(ApprovalModelActions.FETCH_ACTION_APPROVALS, onFetchActionApprovals);
    });
  },
];

export function* onFetchApprovals(
  {
    payload,
  }: PartialPayloadAction<{
    from: Date;
    to: Date;
  }>) {
  yield put(loadingApprovals(true));
  const { from, to } = payload;

  if (!from || !to) {
    yield put(loadingApprovals(false));
    return;
  }

  const { approvalPeriod } = config.groupSetting;
  const controller = new AbortController();
  const { signal } = controller;

  const fromParam = (approvalPeriod === 'weekly' || !approvalPeriod ? timeService.startOfWeek(from) : from).toISOString().split('T')[0];
  const toParam = (approvalPeriod === 'weekly' || !approvalPeriod ? timeService.endOfWeek(to) : to).toISOString().split('T')[0];

  const { data: approvals } = yield restApiClient.approvals.fetchApprovals(signal, new Date(fromParam), new Date(toParam));

  yield put(fetchApprovalsSuccess({ approvals }));
  yield put(loadingApprovals(false));
}

export type ApprovalRecordType = {
  user_id: string;
  from: string;
  to: string;
  status: string;
  for: string;
  attendance?: any;
  auto?: string;
  by?: string;
  commnets?: any;
};

export function* onFetchActionApprovals(
  {
    payload,
  }: PartialPayloadAction<{
    data: ApprovalRecordType;
  }>) {
  yield put(loadingApprovals(true));
  const { data } = payload;

  const controller = new AbortController();
  const { signal } = controller;

  const approvals = yield restApiClient.approvals.fetchActionApprovals(signal, data);

  if (approvals) yield put(updateApprovals({ approval: data }));

  yield put(loadingApprovals(false));
}
