import { ApiClientBase } from '@/services/RestApiClientFactory/modules/ApiClientBase';
import type { IAssistantMessageStreamResponse } from './ApiClientAssistant.types';

export class ApiClientAssistant extends ApiClientBase {
  async assistantMessageStreamResponse({ message, conversationId }: IAssistantMessageStreamResponse) {
    return await this.fetchData({
      method: 'POST',
      queryString: 'chat/message/stream-response',
      body: {
        message,
        conversationId,
      },
    });
  }
}
