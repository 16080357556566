import type { AnyMap } from '@/types/types';
import type { Map } from 'immutable';

export enum ReportModelStateFields {
  SUMMARY_REPORT_ID = 'summaryReportId',
  DETAILED_REPORT_ID = 'detailedReportId',
  REPORT_DETAILED = 'reportDetailed',
  REPORT_DETAILED_SORTED = 'reportDetailedSorted',
  REPORT_DETAILED_COLUMN_SIZING = 'reportDetailedColumnSized',
  REPORT_STATUS = 'reportStatus',
  REPORT_SUMMARY = 'reportSummary',
  REPORT_DATA_IS_LOADING = 'reportDataIsLoading',
  REPORT_META = 'reportMeta',
  REPORT_STATUS_RESPONSE = 'reportStatusResponse',
  REPORT_FILE_STATUS = 'reportFileStatus',
}

export type ReportModel = {
  [ReportModelStateFields.REPORT_SUMMARY]: AnyMap;
  [ReportModelStateFields.REPORT_DETAILED_SORTED]: AnyMap;
  [ReportModelStateFields.REPORT_DETAILED_COLUMN_SIZING]: AnyMap;
  [ReportModelStateFields.REPORT_STATUS_RESPONSE]: number;
  [ReportModelStateFields.REPORT_FILE_STATUS]: number;
};

export type ReportModelState = Map<keyof ReportModel, ReportModel[keyof ReportModel]>;

export enum ReportModelActions {
  INIT_SUMMARY_REPORT = 'INIT_SUMMARY_REPORT',
  FETCH_GROUPED_SUMMARY_REPORT = 'FETCH_GROUPED_SUMMARY_REPORT',
  FETCH_GROUPED_SUMMARY_REPORT_SUCCESS = 'FETCH_GROUPED_SUMMARY_REPORT_SUCCESS',
  FETCH_GROUPED_SUMMARY_REPORT_META_SUCCESS = 'FETCH_GROUPED_SUMMARY_REPORT_META_SUCCESS',
  FETCH_GROUPED_SUMMARY_REPORT_FILE = 'FETCH_GROUPED_SUMMARY_REPORT_FILE',
  SAVE_SUMMARY_REPORT_DATA = 'SAVE_SUMMARY_REPORT_DATA', // for sorting
  SAVE_SUMMARY_REPORT_ID = 'SAVE_SUMMARY_REPORT_ID',
  SET_REPORT_STATUS_RESPONSE = 'SET_REPORT_STATUS_RESPONSE',

  INIT_DETAILED_REPORT = 'INIT_DETAILED_REPORT',
  REFRESH_DETAILED_REPORT = 'REFRESH_DETAILED_REPORT',
  REFRESH_SUMMARY_REPORT = 'REFRESH_SUMMARY_REPORT',
  SAVE_DETAILED_REPORT_ID = 'SAVE_DETAILED_REPORT_ID',
  FETCH_DETAILED_REPORT_SUCCESS = 'FETCH_DETAILED_REPORT_SUCCESS',
  SAVE_DETAILED_REPORT_SORTING_OPTIONS = 'SAVE_DETAILED_REPORT_SORTING_OPTIONS',
  DETAILED_REPORT_DATA_SORT = 'DETAILED_REPORT_DATA_SORT',
  SUMMARY_REPORT_DATA_SORT = 'SUMMARY_REPORT_DATA_SORT',
  FETCH_DETAILED_REPORT_META_SUCCESS = 'FETCH_DETAILED_REPORT_META_SUCCESS',
  FETCH_DETAILED_REPORT_FILE = 'FETCH_DETAILED_REPORT_FILE',
  SET_REPORT_FILE_STATUS = 'SET_REPORT_FILE_STATUS',

  ON_FILTER_CHANGED = 'ON_FILTER_CHANGED',
  FETCH_REPORT_STATUS_UPDATED = 'FETCH_REPORT_STATUS_UPDATED',
  DATA_IS_LOADING_UPDATED = 'DATA_IS_LOADING_UPDATED',
  DETAILED_REPORT_COLUMN_SIZING = 'DETAILED_REPORT_COLUMN_SIZING',
}

export enum BackendReportStatus {
  Waiting = 1,
  Processing = 2,
  Generated = 3,
}

export enum SummaryReportStatus {
  EMPTY_STATUS = '',
  STARTING = 'STARTING',
  WAITING = 'WAITING',
  PROCESSING = 'PROCESSING',
  GENERATED = 'GENERATED',
  FETCHING_GROUPED = 'FETCHING_GROUPED',
  FETCHED_GROUPED = 'FETCHED_GROUPED',
  DOWNLOADED = 'DOWNLOADED',
}

export enum DetailedReportStatus {
  EMPTY_STATUS = '',
  STARTING = 'STARTING',
  WAITING = 'WAITING',
  PROCESSING = 'PROCESSING',
  GENERATED = 'GENERATED',
  FETCHING = 'FETCHING',
  FETCHED = 'FETCHED',
  INVALIDATED = 'INVALIDATED',
  SAVED = 'SAVED',
  DOWNLOADED = 'DOWNLOADED',
}

export enum Column {
  TRACKED_TIME = 'duration',
  NAME = 'name',
}

export type ReportSortingOptionsType = {
  sortBy: string[];
  sortDirection: object;
};

export enum ReportType {
  SUMMARY_REPORT = 'SUMMARY',
  DETAILED_REPORT = 'DETAILED',
}
