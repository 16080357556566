import { ApiClientBase } from '@/services/RestApiClientFactory/modules/ApiClientBase';
import type { ApiClientReportResponse } from '@/services/RestApiClientFactory/modules/ApiClientReport/ApiClientReport.types';
import type { Dict } from '@/types/types';

export class ApiClientReport extends ApiClientBase {
  async fetchReportStatus(
    signal: AbortSignal,
    reportId: string,
  ) {
    return await this.fetchData<ApiClientReportResponse>({
      queryString: `reports/${reportId}/status`,
      apiVersion: 2,
      signal,
    });
  }

  async initializeReport(signal: AbortSignal, params) {
    let result: Dict<string> | null = null;

    try {
      const { data, status } = await this.fetchData<Dict<string>>({
        queryString: 'reports',
        apiVersion: 2,
        method: 'POST',
        body: params,
        signal,
      });

      if (status === 200) {
        result = data;
      }

      if (status >= 400) {
        throw new Error(`${status}`);
      }
    } catch (error) {
      console.error(error);
      throw new Error(error.message);
    }

    return result;
  }

  async getReportFile(
    signal: AbortSignal,
    reportId: string,
    params,
  ) {
    return await this.fetchData<Response>({
      method: 'POST',
      queryString: `reports/${reportId}/export`,
      apiVersion: 2,
      body: params,
      signal,
    });
  }

  async fetchGroupedSummaryReport(
    signal: AbortSignal,
    reportId,
    params,
  ) {
    const { data } = await this.fetchData<Dict<string>>({
      queryString: `reports/${reportId}/group`,
      apiVersion: 2,
      method: 'POST',
      body: params,
      signal,
    });

    return data;
  }

  async fetchDetailedReport(
    signal: AbortSignal,
    reportId: string,
  ) {
    let result: Dict<string> | null = null;

    try {
      const { status, data } = await this.fetchData<Dict<string>>({
        queryString: `reports/${reportId}/raw`,
        apiVersion: 2,
        signal,
      });

      if (status === 200) {
        result = data;
      }
      if (status >= 400) {
        throw new Error(`${status}`);
      }
    } catch (error) {
      console.error(error);
      throw new Error(error.message);
    }

    return result;
  }

  async fetchMeta(
    signal: AbortSignal,
    reportId: string,
    reportType,
  ) {
    let result: Dict<string> | null = null;

    try {
      const { data, status } = await this.fetchData<Dict<string>>({
        queryString: `reports/${reportId}/${reportType}/meta`,
        apiVersion: 2,
        signal,
      });

      if (status === 200) {
        result = data;
      }
      if (status >= 400) {
        throw new Error(`${status}`);
      }
    } catch (error) {
      console.error(error);
      throw new Error(error.message);
    }

    return result;
  }

  async invalidateReportCache(signal: AbortSignal) {
    let result: Dict<string> | null = null;

    try {
      const { data, status } = await this.fetchData<Dict<string>>({
        queryString: 'reports/invalidate',
        apiVersion: 2,
        method: 'POST',
        signal,
      });

      if (status === 200) {
        result = data;
      }

      if (status >= 400) {
        throw new Error(`${status}`);
      }
    } catch (error) {
      console.error(error);
      throw new Error(error.message);
    }

    return result;
  }
}
