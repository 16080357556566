import { configureStore } from '@reduxjs/toolkit';
import { persistStore } from 'redux-persist';
import createSagaMiddleware from 'redux-saga';
import thunk from 'redux-thunk';
import { reducer } from './reducer';
import sagas from './saga';

const sagaMiddleware = createSagaMiddleware();

export const store = configureStore({
  reducer,
  middleware: [thunk, sagaMiddleware],
  devTools: import.meta.env.MODE === 'development',
});

sagaMiddleware.run(sagas());

export const persistor = persistStore(store);

if (import.meta.hot) {
  import.meta.hot.accept('./reducer', (newModule) => {
    store.replaceReducer(newModule.reducer);
  });
}
