import { ApiClientBase } from '@/services/RestApiClientFactory/modules/ApiClientBase';
import type {
  EnableModuleWithDependenciesParams,
  EnableModuleWithDependenciesResponse,
  GetModuleDependenciesParams,
  GetModuleDependenciesResponse,
} from '@/services/RestApiClientFactory/modules/ApiClientModules/ApiClientModules.types';

export class ApiClientModules extends ApiClientBase {
  /**
   * @link https://developer.timecamp.com/docs/timecamp-api/0l62mw7zhoxx0-enable-module-with-dependencies
   */
  async enableModuleWithDependencies(params: EnableModuleWithDependenciesParams) {
    return await this.fetchData<EnableModuleWithDependenciesResponse>({
      queryString: 'module/enable',
      method: 'POST',
      body: params,
    });
  }

  /**
   *
   * @link https://developer.timecamp.com/docs/timecamp-api/z2g175gef04rv-get-module-dependencies
   */
  async getModuleDependencies({ module }: GetModuleDependenciesParams) {
    return (await this.fetchData<GetModuleDependenciesResponse>({
      queryString: `module/dependencies/${module}`,
    })).data;
  }
}
