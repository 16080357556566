import { cva } from 'class-variance-authority';

const DEFAULT_VARIANT = 'info';
const DEFAULT_TEMPLATE = 'cloud';
const DEFAULT_SIZE = 'md';

export const notificationsClasses = cva([
  'notification',
  'relative',
  'inline-flex',
  'items-start',
  'px-16',
  'mb-8',
], {
  variants: {
    variant: {
      info: [
        'bg-gray-50',
        'dark:bg-dark-gray-100',
        'text-gray-700',
        'dark:text-dark-gray-800',
      ],
      error: [
        'bg-error-100',
        'dark:bg-dark-error-100',
        'text-error-600',
        'dark:text-dark-error-800',
      ],
      warning: [
        'notification--warning',
        'bg-warning-100',
        'dark:bg-dark-warning-100',
        'text-warning-700',
        'dark:text-dark-warning-800',
      ],
      success: [
        'bg-primary-50',
        'dark:bg-dark-primary-100',
        'text-primary-600',
        'dark:text-dark-primary-800',
      ],
    },
    template: {
      cloud: [
        'md:min-w-[550px]',
        'shadow-md',
        'rounded-lg',
        'py-16',
      ],
      block: [
        'w-full',
        'rounded-lg',
        'py-16',
      ],
      grid: [
        'w-[calc(100%+60px)]',
        '-ml-30',
        'translate-y-0',
        'justify-center',
        'rounded-none',
        'py-8',
      ],
    },
    size: {
      sm: [
        'text-xs',
        'min-h-0',
      ],
      md: [
        'text-sm',
      ],
    },
    layout: {
      full: [
        'w-full!',
        'translate-x-0!',
      ],
    },
  },
  defaultVariants: {
    variant: DEFAULT_VARIANT,
    template: DEFAULT_TEMPLATE,
    size: DEFAULT_SIZE,
  },
});

export const notificationsIconWrapperClasses = cva([
  'mr-12',
  'inline-flex',
  'items-center',
  'justify-center',
], {
  variants: {
    template: {
      cloud: [],
      grid: [],
      block: [],
    },
    size: {
      sm: [
        'w-20',
        'h-20',
      ],
      md: [
        'w-24',
        'h-24',
        'self-center',
      ],
    },
  },
  defaultVariants: {
    template: DEFAULT_TEMPLATE,
  },
});

export const notificationsCloseWrapperClasses = cva([
  'inline-flex',
  'ml-20',
  'mr-20',
  'hover:opacity-70',
], {
  variants: {
    variant: {
      info: [
        'text-info-200',
        'dark:text-dark-info-300',
      ],
      success: [
        'text-primary-700',
      ],
      error: [
        '',
      ],
      warning: [
        '',
      ],
    },
    template: {
      cloud: [
        'w-20',
        'h-20',
      ],
      grid: [
        'w-24',
        'h-24',
      ],
      block: [
        'w-12',
        'h-12',
      ],
    },
  },
  defaultVariants: {
    template: DEFAULT_TEMPLATE,
  },
});

export const notificationsCloseIconClasses = cva([
  'w-full',
  'h-full',
  'ml-12',
  'cursor-pointer',
], {
  variants: {
    variant: {
      info: [
        'text-info-200',
        'dark:text-dark-info-300',
      ],
      error: [
        'text-error-600',
        'dark:text-dark-error-800',
      ],
      warning: [
        'text-warning-100',
        'dark:text-dark-warning-800',
      ],
      success: [
        'text-primary-600',
        'dark:text-dark-primary-800',
      ],
    },
  },
  defaultVariants: {
    variant: DEFAULT_VARIANT,
  },
});
