import { ApiClientBase } from '@/services/RestApiClientFactory/modules/ApiClientBase';
import type { ITagsListResponse } from '@/services/RestApiClientFactory/modules/ApiClientTagList/ApiClientTagList.types';
import { createQueryString } from '@/services/RestApiClientFactory/utils';
import type { Dict } from '@/types/types';

export class ApiClientTagList extends ApiClientBase {
  async fetchTagLists(signal: AbortSignal, archived: '0' | '1', tags: 0 | 1, taskId?: number) {
    const query = { archived, tags, task_id: taskId, use_restrictions: taskId ? 1 : 0 };
    const queryStringParams = createQueryString(query);
    const { data } = await this.fetchData<ITagsListResponse>({
      queryString: `tag_list${queryStringParams}`,
      signal,
      apiVersion: 1,
    });

    return data;
  }

  public async fetchTagList(signal: AbortSignal, listId) {
    const { data } = await this.fetchData<Dict<string>>({
      queryString: `tag_list/${listId}`,
      signal,
    });

    return data;
  }
}
