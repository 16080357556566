import { createContext, useContext, useEffect, useState } from 'react';
import { useDarkMode } from 'usehooks-ts';
import { Theme as SettingTheme } from '@/redux/models/SettingModel/types';
import { useQueryConfig } from '@/reactQuery/Config/hooks/useQueryConfig/useQueryConfig';
import { defaultThemeContext } from '@/contexts/constants';
import { Theme } from '@/contexts/interfaces';
import { config } from '@/config';
import type { PropsWithChildren } from 'react';

const ThemeContext = createContext(defaultThemeContext);

const settingThemeMapper = (theme: SettingTheme) => {
  if (theme === SettingTheme.DARKMODE) return Theme.DARK;

  return Theme.MODERN;
};

const ThemeProvider = ({ children }: PropsWithChildren) => {
  const { userSetting: { theme = SettingTheme.DEFAULT } = {} } = useQueryConfig()?.data || {};
  const [currentTheme, setCurrentTheme] = useState<Theme>(() => settingThemeMapper(theme));
  const { isDarkMode } = useDarkMode();
  const { userId } = config;

  const setThemeHandler = (e) => {
    const { theme } = e.detail;

    setCurrentTheme(settingThemeMapper(theme));
    document.body.setAttribute('data-theme', theme);
  };

  useEffect(() => {
    if (!userId) {
      const value = isDarkMode ? SettingTheme.DARKMODE : SettingTheme.DEFAULT;
      document.documentElement.setAttribute('data-theme', value);
    }
  }, [isDarkMode]);

  useEffect(() => {
    window.addEventListener('theme-changed', setThemeHandler);

    return () => {
      window.removeEventListener('theme-changed', setThemeHandler);
    };
  }, []);

  useEffect(() => {
    const setThemeOnSaveSettings = (e) => {
      setThemeHandler(e);
    };

    window.addEventListener('settings-saved', setThemeOnSaveSettings);

    return () => {
      window.removeEventListener('settings-saved', setThemeOnSaveSettings);
    };
  }, []);

  return (
    <ThemeContext.Provider
      value={{
        theme: currentTheme,
      }}
    >
      {children}
    </ThemeContext.Provider>
  );
};

const useThemeContext = () => useContext(ThemeContext);

export { ThemeProvider, ThemeContext, useThemeContext };
