import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { customIcons } from './customIcons';
import type { IconLookup } from '@fortawesome/fontawesome-common-types';
import type { FontAwesomeIconProps } from '@fortawesome/react-fontawesome';
import type React from 'react';

export interface Icon extends Partial<FontAwesomeIconProps> {
  icon?: IconLookup;
  className?: string;
}

export const Icon: React.FC<Icon> = (props: Icon) => {
  const {
    icon,
    className = '',
    ...iconProps
  } = props;

  if (!icon) {
    return null;
  }

  const extendedClassName = `${className} dark:invert dark:brightness-0`;

  if (icon.iconName in customIcons) {
    return (<img className={extendedClassName} src={customIcons[icon.iconName]} />);
  }

  return <FontAwesomeIcon icon={icon} className={className} {...iconProps} />;
};
